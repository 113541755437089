import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Row,
  Col,
  Table,
  Typography,
  Tag,
  Space,
  Input,
  Button,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";

import * as api from "../api/analytics";
import echo from "../socket";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Analytics = () => {
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dateFilters, setDateFilters] = useState([]);
  const [activity, setActivity] = useState(null);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  let searchInput;

  useEffect(() => {
    getActivities({ pagination });
  }, []);

  const getActivities = (params = {}) => {
    let LoggedInUser = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    api
      .getActivities(params, LoggedInUser.token)
      .then((response) => {
        setLoading(false);
        const results = response.data.activities.map((activity) => ({
          key: activity.id,
          staff: activity.staff.name,
          station: activity.station.name,
          status: activity.status,
          location: activity.geo_code.location,
          date_posted: activity.created_at,
        }));
        setPagination({
          current: response.data.pagination.current_page,
          pageSize: response.data.pagination.per_page,
          total: response.data.pagination.total,
        });
        setData(results);
      })
      .catch((err) => {
        setLoading(false);
        const error = err?.response?.data;
        if (error?.message === "Unauthenticated") {
          setUser(null);
        }
        message.error(error?.message);
        console.error(error);
      });
  };

  useEffect(() => {
    if (user === null) {
      localStorage.removeItem("user");
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    echo.channel("live-eagle-activities").listen("ActivityCreated", (e) => {
      setActivity(e.results);
    });
  }, []);

  useEffect(() => {
    if (activity !== null) {
      setData([
        {
          key: activity?.id,
          staff: activity?.staff?.name,
          station: activity?.station?.name,
          status: activity?.status,
          location: activity?.geo_code?.location,
          date_posted: activity?.created_at,
        },
        ...data,
      ]);
    }
  }, [activity]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleTableChange = (pagination, filters) => {
    getActivities({ pagination, filters, dateFilters });
  };

  const columns = [
    {
      title: "Staff",
      dataIndex: "staff",
      key: "staff",
    },
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      filters: [
        { text: "TV3", value: "TV3" },
        { text: "ONUA TV", value: "ONUA TV" },
        { text: "METRO TV", value: "METRO TV" },
        { text: "UTV", value: "UTV" },
        { text: "MAX TV", value: "MAX TV" },
        { text: "JOY PRIME", value: "JOY PRIME" },
        { text: "CITI TV", value: "CITI TV" },
        { text: "GHONE TV", value: "GHONE TV" },
        { text: "TV AFRICA", value: "TV AFRICA" },
      ],
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "ON", value: "on" },
        { text: "OFF", value: "off" },
      ],
      width: "20%",
      key: "status",
      render: (status) =>
        status.stat === "off" ? (
          <>
            <Tag color="red">{status.stat.toUpperCase()}</Tag>
            <Tag color="red">
              {status.totally_off === "true"
                ? "TOTALLY OFF"
                : "INTERMITTENT FREEZES"}
            </Tag>
            <Tag color="red">{status.transmission_type.toUpperCase()}</Tag>
          </>
        ) : (
          <Tag color="green">{status.stat.toUpperCase()}</Tag>
        ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ...getColumnSearchProps("location"),
    },
    {
      title: "Date Posted",
      dataIndex: "date_posted",
      key: "status",
    },
  ];

  const onChange = (date, dateString) => {
    let dateFilters = dateString;
    setDateFilters(dateFilters);
    getActivities({ dateFilters });
  };

  return (
    <div>
      <Row gutter={[40, 0]}>
        <Col span={18}>
          <Title level={2}>Activities</Title>
        </Col>
        <Col span={6} style={{ display: "flex", justifyContent: "end" }}>
          <RangePicker size="large" onChange={onChange} showToday={true} />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={24} style={{ marginTop: "20px" }}>
          <Table
            columns={columns}
            rowKey={(record) => record.key}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
