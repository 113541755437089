import apiCall from "./apiCall";

export const getActivities = async (params, token) => {
  const response = await apiCall.get("/api/activity", {
    params: {
      page: params?.pagination?.current,
      per_page: params?.pagination?.pageSize,
      "filter[status_filter]": params?.filters?.status?.toString(),
      "filter[station_name]": params?.filters?.station?.toString(),
      "filter[starts_between_filter]":
        params?.dateFilters?.toString() === ""
          ? undefined
          : params?.dateFilters?.toString(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
