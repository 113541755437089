import React, { useState, useEffect } from "react";

import { Layout } from "antd";

import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

import SideNav from "../../components/layouts/SideNav";

import DropdownClass from "../../components/utilities/DropdownClass";
import Analytics from "../../components/pages/Analytics";
import Dashboard from "../../components/pages/Dashboard";

import { Route, Routes, Navigate, useLocation } from "react-router-dom";

const { Header, Sider, Content, Menu, Dropdown, Col } = Layout;

const PrivateElement = ({ children }) => {
  let LoggedUser = JSON.parse(localStorage.getItem("user"));
  let location = useLocation();
  return LoggedUser ? children : <Navigate to="/" state={{ from: location }} />;
};

const LayoutRender = () => {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    window.innerWidth <= 760 ? setCollapse(true) : setCollapse(false);
  }, []);

  const handleToggle = (event) => {
    event.preventDefault();
    collapse ? setCollapse(false) : setCollapse(true);
  };
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapse} width={300}>
        <SideNav collapse={collapse} />
      </Sider>
      <Layout>
        <Header
          className="siteLayoutBackground"
          style={{ padding: 0, background: "#fff" }}
        >
          {React.createElement(
            collapse ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: handleToggle,
              style: {
                color: "darkgray",
                fontSize: "25px",
                marginLeft: "20px",
                lineHeight: "74px",
              },
            }
          )}
          <DropdownClass />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: "calc(100vh - 114px)",
            //   background: "#fff",
          }}
        >
          <Routes>
            <Route
              path="dashboard"
              element={
                <PrivateElement>
                  <Dashboard />
                </PrivateElement>
              }
            ></Route>
            <Route
              path="/activities/analytics"
              element={
                <PrivateElement>
                  <Analytics />
                </PrivateElement>
              }
            ></Route>
            {/* <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/activities/analytics" element={<Analytics />} /> */}
            {/* <Route path="/videos" element={<Video />} /> */}
            {/* <Route path="/" element={<Navigate replace to="/list" />} /> */}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutRender;
