import React from "react";
import { Menu, Dropdown, message, Avatar, Space } from "antd";
import { DownOutlined, LogoutOutlined, UnlockTwoTone } from "@ant-design/icons";
import * as api from "../api/auth";
import { useNavigate } from "react-router";

const DropdownClass = () => {
  let navigate = useNavigate();

  let LoggedUser = JSON.parse(localStorage.getItem("user"));

  const onClick = ({ key }) => {
    let LoggedInUser = JSON.parse(localStorage.getItem("user"));
    api
      .Logout(LoggedInUser.token)
      .then((response) => {
        localStorage.clear();
        message.error("Logged Out");
        navigate("/");
      })
      .catch((err) => {
        const error = err.response.data;
        console.error(error);
      });
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="1">
        <UnlockTwoTone />
        {"   Logout"}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <a
        className="ant-dropdown-link"
        onClick={(e) => e.preventDefault()}
        style={{ float: "right", marginRight: "20px" }}
      >
        <Avatar
          style={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            marginRight: "5px",
          }}
        >
          {LoggedUser?.name[0]}
        </Avatar>
        {LoggedUser?.name}
        <DownOutlined style={{ marginLeft: "5px" }} />
      </a>
    </Dropdown>
  );
};

export default DropdownClass;
