import apiCall from "./apiCall";

export const getStationsSummary = async (from = null, to = null, token) => {
  let response = "";
  if (from === null) {
    response = await apiCall.get("/api/activity/stations-summary_v2", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    response = await apiCall.get("/api/activity/stations-summary_v2", {
      params: {
        from: from,
        to: to,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return response.data;
};

export const getProblemLocations = async (from = null, to = null, token) => {
  let response = "";
  if (from === null) {
    response = await apiCall.get("/api/activity/locations-summary", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    response = await apiCall.get("/api/activity/locations-summary", {
      params: {
        from: from,
        to: to,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return response.data;
};
