import React from "react";
import HighchartsReact from "highcharts-react-official";

import "./Chart.css";

const Chart = (props) => (
  <HighchartsReact
    constructorType="chart"
    allowChartUpdate={true}
    immutable={true}
    updateArgs={[true, true, true]}
    containerProps={{ className: "chartContainer" }}
    {...props}
  />
);
export default Chart;
