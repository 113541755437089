import apiCall from "./apiCall";

export const login = async (formValues) => {
  const response = await apiCall.post("/api/auth/login", formValues);
  const { data } = response.data;
  const userProfile = await getUserProfile(data.token);
  return userProfile;
};

export const Logout = async (token) => {
  const response = await apiCall.post(
    "/api/auth/logout",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getUserProfile = async (token) => {
  const response = await apiCall.get("/api/user/details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = response.data;
  const userData = { token, ...data.user };
  return userData;
};

export const getProblemLocations = async (from = null, to = null) => {
  let response = "";
  if (from === null) {
    response = await apiCall.get("/api/activity/locations-summary");
  } else {
    response = await apiCall.get("/api/activity/locations-summary", {
      params: {
        from: from,
        to: to,
      },
    });
  }
  return response.data;
};
