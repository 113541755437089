import React from "react";
import { Menu, Row, Col } from "antd";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import "../App.css";

const SideNav = ({ collapse }) => {
  const history = useNavigate();

  const handleDashboardClick = () => {
    history("/dashboard");
  };

  const handleAnalyticsClick = () => {
    history("/activities/analytics");
  };

  return (
    <div>
      <div
        theme="dark"
        style={{
          height: "32px",
          //   background: "rgba(255, 255, 255, 0.2)",
          margin: "16px",
        }}
      >
        <Row>
          <Col
            flex="auto"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span>
              <img
                src="https://talented-kids.s3.us-east-2.amazonaws.com/eagle-eye.jpeg"
                alt=""
                srcSet=""
                width={45}
              />
            </span>
            <Col
              flex="auto"
              className={collapse ? `button-close` : `button-open`}
              style={{ marginLeft: "10px" }}
            >
              <span
                style={{ color: "#fff" }}
                // className={collapse ? `button-close` : `button-open`}
              >
                EAGLE EYE
              </span>
            </Col>
          </Col>
        </Row>
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="1" onClick={handleDashboardClick}>
          <UserOutlined />
          <span> Dashboard</span>
        </Menu.Item>
        <Menu.Item key="2" onClick={handleAnalyticsClick}>
          <VideoCameraOutlined />
          <span> Analytics</span>
        </Menu.Item>
        {/* <Menu.Item key="3" onClick={handleFileClick}>
          <UploadOutlined />
          <span> Files</span>
        </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default SideNav;
