import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Image,
  Card,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";

import * as api from "../api/auth";

const Login = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const onFinish = (values) => {
    setLoading(true);
    api
      .login(values)
      .then((user) => {
        setLoading(false);
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        navigate("/dashboard");
        message.success("Logged In Successfully");
      })
      .catch((err) => {
        setLoading(false);
        const error = err?.response?.data;
        message.error(error.message);
        console.error(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    let LoggedUser = JSON.parse(localStorage.getItem("user"));
    if (LoggedUser) {
      setUser(LoggedUser);
    } else {
      setUser(null);
    }
  }, []);

  if (user) {
    navigate("/dashboard");
  }

  return (
    <Row
      align="center"
      justify="center"
      style={{ height: "100vh", alignItems: "center" }}
    >
      <Col xs={{ span: 24 }} lg={{ span: 10 }}>
        <Card
          style={{
            background: "lightblue",
          }}
        >
          <Row align="center" justify="center">
            <Col>
              <Image
                width={100}
                src="https://talented-kids.s3.us-east-2.amazonaws.com/eagle-eye.jpeg"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }} justify="center">
            <Col span={18}>
              <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a valid email",
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{ offset: 4, span: 16 }}
                >
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
