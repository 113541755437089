import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Card,
  Space,
  Statistic,
  DatePicker,
  Spin,
  Skeleton,
  List,
  Popover,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import Highcharts from "highcharts";
import Chart from "../Graph/Chart";
import echo from "../socket";

import * as api from "../api/dashboard";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Dashboard = () => {
  let navigate = useNavigate();
  const [stationsSummary, setStationsSummary] = useState([]);
  const [fetchingData, isFetchingData] = useState(false);
  const [results, setResults] = useState(null);
  const [zoneList, setZoneList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const content = (summary) => (
    <div>
      <span style={{ fontWeight: 600 }}>Totally Off</span>:{" "}
      <span style={{ color: "red" }}>{summary?.totally_off}</span> <br />
      <span style={{ fontWeight: 600 }}>Intermittent Freezes</span>:{" "}
      <span style={{ color: "red" }}>{summary?.partially_off}</span> <br />
      <hr style={{ color: "#f0f0f0" }} />
      <span style={{ fontWeight: 600 }}>Transmission (DTH)</span>:{" "}
      <span style={{ color: "red" }}>{summary?.DTH}</span> <br />
      <span style={{ fontWeight: 600, lineHeight: "1.5" }}>
        Transmission (DTT)
      </span>
      : <span style={{ color: "red" }}>{summary?.DTT}</span>
      <br />
    </div>
  );

  useEffect(() => {
    getStationsSummary();
    getProblemLocations();
  }, []);

  const onChange = (date, dateString) => {
    getStationsSummary(dateString[0], dateString[1]);
    getProblemLocations(dateString[0], dateString[1]);
  };

  const getProblemLocations = (from = null, to = null) => {
    let LoggedInUser = JSON.parse(localStorage.getItem("user"));
    setLoading(true);
    api
      .getProblemLocations(from, to, LoggedInUser.token)
      .then((response) => {
        setLoading(false);
        setLocationsData(response.data);
      })
      .catch((err) => {
        setLoading(false);
        const error = err?.response?.data;
        if (error?.message === "Unauthenticated") {
          setUser(null);
        }
        message.error(error?.message);
        console.error(error);
      });
  };

  const getStationsSummary = (from = null, to = null) => {
    let LoggedInUser = JSON.parse(localStorage.getItem("user"));
    isFetchingData(true);
    api
      .getStationsSummary(from, to, LoggedInUser.token)
      .then((response) => {
        isFetchingData(false);
        setStationsSummary(response.data);
      })
      .catch((err) => {
        isFetchingData(false);
        const error = err?.response?.data;
        if (error?.message === "Unauthenticated") {
          setUser(null);
        }
        console.error(error);
      });
  };

  useEffect(() => {
    if (user === null) {
      localStorage.removeItem("user");
      navigate("/");
    }
  }, [user, navigate]);

  const options = {
    chart: {
      type: "column",
      width: "1668",
    },
    title: {
      text: "Report of TV Stations",
    },
    subtitle: {
      text: "Eagle Eye",
    },
    xAxis: {
      categories: stationsSummary.map((station) => station.station_name),
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Values (count)",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "ON",
        data: stationsSummary.map((station) => station.ON),
        color: "#3f8600",
      },
      {
        name: "OFF",
        data: stationsSummary.map((station) => station.OFF),
        color: "#cf1322",
      },
    ],
  };

  useEffect(() => {
    echo
      .channel("live-eagle-stations-summary")
      .listen("StationSummaryCreated", (e) => {
        setResults(e.results);
      });
    echo
      .channel("live-eagle-problem-locations")
      .listen("ProblemLocationsCreated", (e) => {
        setZoneList(e.results);
      });
  }, []);

  useEffect(() => {
    if (results !== null) {
      const new_items = stationsSummary.map((x) =>
        x.id === results.id ? results : x
      );
      setStationsSummary(new_items);
    }
  }, [results]);

  useEffect(() => {
    if (zoneList.length !== 0) {
      setLocationsData(zoneList);
    }
  }, [zoneList]);

  const renderStatistics = stationsSummary?.map((summary) => {
    return (
      <Card title={summary?.station_name} key={summary.id}>
        <Space size={70}>
          <Statistic
            title="ON"
            value={summary?.ON}
            valueStyle={{ color: "#3f8600" }}
            prefix={<ArrowUpOutlined />}
            suffix=""
            key={"ON"}
          />
          <Statistic
            title="OFF"
            value={summary?.OFF}
            valueStyle={{ color: "#cf1322" }}
            prefix={
              <>
                <Popover content={content(summary)} title="Breakdown">
                  <ArrowUpOutlined />
                </Popover>
              </>
            }
            suffix=""
            key={"OFF"}
          />
        </Space>
      </Card>
    );
  });
  return (
    <div>
      <Row gutter={[40, 0]}>
        <Col span={18}>
          <Title level={2}>Overview</Title>
        </Col>
        <Col span={6} style={{ display: "flex", justifyContent: "end" }}>
          <RangePicker size="large" onChange={onChange} showToday={true} />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span="15">
          {fetchingData ? (
            <>
              <Space style={{ display: "flex", justifyContent: "center" }}>
                <Spin />
              </Space>
            </>
          ) : (
            <>
              <Space size={[30, 30]} wrap>
                {renderStatistics}
              </Space>
            </>
          )}
        </Col>
        <Col span="9">
          <Card style={{ width: "100%", marginTop: "1rem" }}>
            <Title level={3}>Problem Locations</Title>
            <List
              itemLayout="horizontal"
              dataSource={locationsData}
              loading={loading}
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 10,
              }}
              renderItem={(item) => (
                <List.Item
                  key={item.city}
                  actions={[
                    <p style={{ color: "#cf1421" }}>
                      <ArrowDownOutlined />
                      {item?.city_count}
                    </p>,
                  ]}
                >
                  <Typography.Text mark>[CITY]</Typography.Text> {item?.city}
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[40, 5]} style={{ marginTop: "50px" }}>
        <Col span="24">
          <Card style={{ width: "100%" }}>
            <Title level={3}>Stations Report</Title>
            {fetchingData ? (
              <Skeleton loading active />
            ) : (
              <Chart options={options} highcharts={Highcharts}></Chart>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
